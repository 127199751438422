var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useAssignmentData } from './AssignmentContext';
import { FaChevronUp, FaAngleRight } from "react-icons/fa6";
import { motion } from 'framer-motion';
var SingleQuestionDisplay = function (_a) {
    var questionId = _a.questionId, onFinished = _a.onFinished;
    var _b = useAssignmentData(), assignment = _b.assignment, assignmentResponse = _b.assignmentResponse, setAssignmentResponse = _b.setAssignmentResponse;
    var question = useState(assignment.questions[questionId])[0];
    var _c = useState({}), showAnswerInfo = _c[0], setShowAnswerInfo = _c[1];
    var getResponseData = useCallback(function () {
        if (!assignmentResponse) {
            return;
        }
        if (!assignmentResponse.questionResponses[questionId]) {
            return;
        }
        return assignmentResponse.questionResponses[questionId];
    }, [assignmentResponse, questionId]);
    var isQuestionComplete = React.useCallback(function () {
        var responseData = getResponseData();
        if (!responseData) {
            return false;
        }
        return responseData.answerSelectionOrder.includes(question.correctAnswerId);
    }, [getResponseData, question.correctAnswerId]);
    var answerClicked = React.useCallback(function (index) {
        var responseData = getResponseData();
        if (!responseData) {
            return;
        }
        if (responseData.answerSelectionOrder.includes(index)) {
            if (question.correctAnswerId === index) {
                setShowAnswerInfo(function (old) {
                    var x = __assign({}, old);
                    x[index] = true;
                    return x;
                });
            }
            else {
                setShowAnswerInfo(function (old) {
                    var x = __assign({}, old);
                    x[index] = !x[index];
                    return x;
                });
            }
        }
        else {
            setShowAnswerInfo(function (prev) {
                var _a;
                var showingCorrect = prev[question.correctAnswerId];
                var newShowAnswerInfo = (_a = {}, _a[index] = true, _a);
                if (showingCorrect) {
                    newShowAnswerInfo[question.correctAnswerId] = true;
                }
                return newShowAnswerInfo;
            });
            setAssignmentResponse(function (prev) {
                var newResponse = __assign({}, prev);
                newResponse.questionResponses[questionId].answerSelectionOrder.push(index);
                return newResponse;
            });
        }
    }, [getResponseData, setAssignmentResponse, questionId, question.correctAnswerId]);
    var optionColor = React.useCallback(function (index) {
        var responseData = getResponseData();
        if (!responseData) {
            return;
        }
        if (!responseData.answerSelectionOrder.includes(index)) {
            return "bg-white";
        }
        else {
            if (question.correctAnswerId === index) {
                return "bg-green-100 shadow-md";
            }
            else {
                return "bg-red-100 shadow-md";
            }
        }
    }, [getResponseData, question]);
    var labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    if (!question) {
        return null;
    }
    return (_jsxs("div", __assign({ className: 'relative h-full pt-4 p-2 flex flex-col overflow-hidden' }, { children: [_jsxs("div", __assign({ className: 'rounded-md bg-white p-2 shadow-md' }, { children: [questionId + 1, ". ", question.question] })), _jsx("div", { className: "transition-all overflow-hidden h-auto ".concat(isQuestionComplete() ? "max-h-24" : "max-h-0") }), _jsx("hr", { className: "border-t-2 border-gray-300 mt-4 mb-4" }), _jsx("div", __assign({ className: 'flex flex-col gap-2 h-full overflow-y-auto pb-4' }, { children: question.options.map(function (option, index) { return (_jsxs("div", __assign({ className: 'relative' }, { children: [_jsxs("div", __assign({ className: "relative rounded-md ".concat(optionColor(index), " p-2 cursor-pointer hover:shadow-md z-10"), onClick: function () { return answerClicked(index); } }, { children: [labels[index], ". ", option.answer] })), _jsxs(motion.div, __assign({ initial: { maxHeight: 0, opacity: 0 }, animate: showAnswerInfo[index] ? { maxHeight: 256, opacity: 1 } : { maxHeight: 0, opacity: 0 }, transition: { duration: 0.3, ease: "easeInOut" }, className: 'relative ml-2 mr-2 rounded-bl-md rounded-br-md bg-gray-200 shadow-md overflow-hidden p-2 overflow-y-scroll' }, { children: [option.learningInfo ? option.learningInfo : "No information available", index === question.correctAnswerId && (_jsx("div", __assign({ className: 'flex place-content-center' }, { children: _jsxs("div", __assign({ className: 'bg-green-300 text-mirno-dark p-2 mt-4 mb-4 rounded-md shadow-md cursor-pointer', onClick: onFinished }, { children: ["Continue", _jsx(FaAngleRight, { className: 'inline ml-2' })] })) })))] }))] }), index)); }) }))] })));
};
var AssignmentQuestionDisplay = function (_a) {
    var onFinished = _a.onFinished;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useAssignmentData(), assignment = _c.assignment, assignmentResponse = _c.assignmentResponse, setAssignmentResponse = _c.setAssignmentResponse;
    var _d = useState(0), currentQuestionId = _d[0], setCurrentQuestionId = _d[1];
    var barHeight = 'h-12';
    useEffect(function () {
        if (assignmentResponse.questionResponses.length === 0) {
            setAssignmentResponse(function (prev) {
                var newResponse = __assign({}, prev);
                newResponse.questionResponses = assignment.questions.map(function () { return ({ answerSelectionOrder: [] }); });
                console.log('Setting initial response:', newResponse);
                return newResponse;
            });
        }
    });
    var onQuestionFinished = useCallback(function () {
        if (currentQuestionId === assignment.questions.length - 1) {
            onFinished();
        }
        else {
            setCurrentQuestionId(function (old) { return old + 1; });
        }
    }, [currentQuestionId, assignment, onFinished]);
    if (!assignment) {
        return null;
    }
    function getHeaderText() {
        if (isOpen) {
            return "Hide question";
        }
        else {
            var fullText = "".concat(currentQuestionId + 1, ". ").concat(assignment.questions[currentQuestionId].question);
            if (fullText.length > 145) {
                return fullText.slice(0, 140) + "...";
            }
            else {
                return fullText;
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "".concat(barHeight, " -mt-1 bg-transparent") }, { children: "\u00A0" })), _jsxs("div", __assign({ className: "absolute bottom-0 left-0 w-full z-[99999999] transition-[height] duration-500 ease-in-out flex flex-col ".concat(isOpen ? 'h-full' : barHeight) }, { children: [_jsxs("div", __assign({ className: "".concat(barHeight, " cursor-pointer bg-gray-200 gap-2 flex items-center justify-center ").concat(isOpen ? 'h-auto' : barHeight, " rounded-tl-md rounded-tr-md select-none drag-handle"), onMouseDown: function () { return setIsOpen(function (old) { return !old; }); }, onTouchStart: function () { return setIsOpen(function (old) { return !old; }); } }, { children: [_jsx("span", __assign({ className: "ml-2 overflow-hidden mt-auto" }, { children: getHeaderText() })), _jsx(FaChevronUp, { className: "w-12 transform transition-transform ".concat(isOpen ? 'rotate-180' : 'rotate-0') })] })), _jsx("div", __assign({ className: "bg-gray-100 h-full overflow-hidden" }, { children: assignment.questions.map(function (_, index) { return (_jsx("div", __assign({ className: "absolute pb-8 transition-all h-full overflow-scroll ".concat(currentQuestionId === index ? "opacity-100" : "opacity-0 pointer-events-none") }, { children: _jsx(SingleQuestionDisplay, { questionId: index, onFinished: onQuestionFinished }) }), index)); }) }))] }))] }));
};
export default AssignmentQuestionDisplay;
