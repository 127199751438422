var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppContext } from '../../AppContext';
import MedagogicLogo from "../../assets/mirno_icon.png";
import "./AssignmentMode.css";
var ViewerLaunchScreen = function (_a) {
    var onLaunch = _a.onLaunch;
    var authedUser = useAppContext().authedUser;
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: MedagogicLogo, className: 'w-12 pointer-events-none', alt: "Medagogic Logo" }), authedUser &&
                _jsx("button", __assign({ className: "simple", onClick: onLaunch }, { children: "Launch" }))] }));
};
export default ViewerLaunchScreen;
