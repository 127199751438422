var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../AppContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VirtualPatientStateStorage from '../../components/Database/VirtualPatientStateStorage';
import ViewerLaunchScreen from './Viewer_LaunchScreen';
import ViewerAudioCheck from './Viewer_AudioCheck';
import ViewerScenarioIntro from './Viewer_AssignmentIntro';
import ArcwareView from '../../components/Arcware/Arcware';
import Icon_Close from "./Icon_Close.svg";
import Icon_CloseLight from "./Icon_CloseLight.svg";
import MirnoIcon from "../../assets/mirno_icon.png";
import Button_Monitor from "./Button_Monitor.svg";
import Button_Monitor_Active from "./Button_Monitor_Active.svg";
import Monitor from '../../components/Monitor/Monitor';
import ViewerComplete from './Viewer_Complete';
import ViewerAnswersSubmitted from './Viewer_AnswersSubmitted';
import { useAssignmentData } from '../../components/StudentMode/AssignmentContext';
import MedagogicLogo from "../../assets/mirno_icon.png";
import Icon_Success from "./Icon_Success.svg";
import ViewerAssignmentList from './Viewer_AssignmentList';
import Markdown from 'react-markdown';
import ScenarioInfoSVG from "./ScenarioInfoSymbol.svg";
import { GoScreenFull as GoFullScreenIcon } from "react-icons/go";
import StethoscopeControl from '../../components/DemoControls/StethoscopeControl';
import ExaminationControl from '../../components/DemoControls/ExaminationControl';
import DemoControlGroup from '../../components/DemoControls/DemoControlCommon';
import ReportsControl from '../../components/DemoControls/ReportsControl';
import { useVirtualPatientStore } from '../../storage/VirtualPatientStore';
import "./AssignmentMode.css";
import AssignmentQuestionDisplay from '../../components/StudentMode/AssignmentQuestionDisplay';
import { AnimatePresence } from 'framer-motion';
import { FullscreenBloodGasView } from '../../components/Reuseable/ReportDisplays/BloodGasReport';
import AssignmentStorage from '../../competency/AssignmentStorage';
var ViewerState;
(function (ViewerState) {
    // LOGIN,
    ViewerState[ViewerState["LAUNCH_ASSIGNMENT"] = 0] = "LAUNCH_ASSIGNMENT";
    ViewerState[ViewerState["AUDIO_TEST"] = 1] = "AUDIO_TEST";
    ViewerState[ViewerState["SCENARIO_INTRO"] = 2] = "SCENARIO_INTRO";
    ViewerState[ViewerState["TUTORIAL"] = 3] = "TUTORIAL";
    ViewerState[ViewerState["IN_ASSIGNMENT"] = 4] = "IN_ASSIGNMENT";
    ViewerState[ViewerState["EXERCISE_COMPLETE"] = 5] = "EXERCISE_COMPLETE";
    ViewerState[ViewerState["ANSWERS_SUBMITTED"] = 6] = "ANSWERS_SUBMITTED";
})(ViewerState || (ViewerState = {}));
;
var AssignmentMode = function () {
    var loadPreset = useAppContext().loadPreset;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    // const [searchParams,] = useSearchParams();
    var navigate = useNavigate();
    var location = useLocation();
    // const [assignmentId, selectAssignment] = useState<string | undefined>(searchParams.get('assignmentId') || undefined);
    var _a = React.useState(), scenarioIntro = _a[0], setScenarioIntro = _a[1];
    var _b = useState(false), showMonitor = _b[0], setShowMonitor = _b[1];
    var _c = useState(false), showAssignmentInfoOverlay = _c[0], setShowAssignmentInfoOverlay = _c[1];
    var _d = useAssignmentData(), assignmentResponse = _d.assignmentResponse, contextAssignmentData = _d.assignment, setAssignment = _d.setAssignment, setAssignmentResponse = _d.setAssignmentResponse;
    var urlParam_assignmentId = useParams().assignmentId;
    var _e = useState(false), showBloodGasReport = _e[0], setShowBloodGasReport = _e[1];
    var _f = React.useState(function () {
        var _a;
        return ((_a = location.state) === null || _a === void 0 ? void 0 : _a.viewerState) || ViewerState.LAUNCH_ASSIGNMENT;
    }), viewerState = _f[0], __setViewerState = _f[1];
    var changeViewerState = useCallback(function (newState, replace) {
        if (replace === void 0) { replace = false; }
        __setViewerState(newState);
        var queryString = location.search; // Capture existing query string
        if (replace) {
            navigate("".concat(location.pathname).concat(queryString), { state: { viewerState: newState }, replace: true });
        }
        else {
            navigate("".concat(location.pathname).concat(queryString), { state: { viewerState: newState } });
        }
        console.log('Changed viewer state to:', newState);
    }, [navigate, location.search, location.pathname]);
    useEffect(function () {
        function loadAssignment(assignmentId) {
            return __awaiter(this, void 0, void 0, function () {
                var assignment;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!assignmentId)
                                return [2 /*return*/];
                            console.log('Assignment ID:', assignmentId);
                            return [4 /*yield*/, AssignmentStorage.getAssignment(assignmentId)];
                        case 1:
                            assignment = _a.sent();
                            if (!assignment) {
                                console.error('Assignment not found:', assignmentId);
                                return [2 /*return*/];
                            }
                            setAssignment(assignment);
                            changeViewerState(ViewerState.SCENARIO_INTRO, true);
                            return [2 /*return*/];
                    }
                });
            });
        }
        // Only run if assignmentId is present and not already loaded
        if (urlParam_assignmentId && !contextAssignmentData) {
            loadAssignment(urlParam_assignmentId);
        }
    }, [urlParam_assignmentId, contextAssignmentData, setAssignment, changeViewerState]);
    useEffect(function () {
        if (contextAssignmentData && contextAssignmentData.docId && assignmentResponse.assignmentId !== contextAssignmentData.docId) {
            setAssignmentResponse(__assign(__assign({}, assignmentResponse), { assignmentId: contextAssignmentData.docId }));
        }
    }, [contextAssignmentData, setAssignmentResponse, assignmentResponse]);
    useEffect(function () {
        if (contextAssignmentData) {
            setScenarioIntro(contextAssignmentData.introduction || '');
            var virtualPatientId = contextAssignmentData.rootPatientId;
            VirtualPatientStateStorage.getPreset(virtualPatientId).then(function (loadedVirtualPatient) {
                if (loadedVirtualPatient) {
                    loadPreset(loadedVirtualPatient.virtualPatientState);
                }
            });
        }
    }, [contextAssignmentData, loadPreset]);
    useEffect(function () {
        var _a;
        var stateViewerState = (_a = location.state) === null || _a === void 0 ? void 0 : _a.viewerState;
        if (stateViewerState !== undefined && stateViewerState !== viewerState) {
            __setViewerState(stateViewerState);
        }
    }, [location, viewerState]);
    function goFullScreen() {
        document.documentElement.requestFullscreen();
    }
    function onLaunchPressed() {
        goFullScreen();
        changeViewerState(ViewerState.AUDIO_TEST);
    }
    function exitFullScreen() {
        document.exitFullscreen();
    }
    var _g = useState(false), isFullscreen = _g[0], setIsFullscreen = _g[1];
    useEffect(function () {
        function updateFullscreenStatus() {
            setIsFullscreen(!!document.fullscreenElement);
        }
        document.addEventListener('fullscreenchange', updateFullscreenStatus);
        return function () {
            document.removeEventListener('fullscreenchange', updateFullscreenStatus);
        };
    }, []);
    function finishedSubmittingAnswers() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // console.log("Submitting answers...", assignmentResponse);
                return [2 /*return*/];
            });
        });
    }
    function onSelectedAssignment(assignment) {
        console.log('Selected assignment:', assignment);
        setAssignment(assignment);
        changeViewerState(ViewerState.SCENARIO_INTRO);
    }
    if (assignmentResponse.isSubmitted && viewerState !== ViewerState.IN_ASSIGNMENT) {
        return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden items-center py-24 justify-between' }, { children: [_jsx("img", { src: MedagogicLogo, className: 'w-12 pointer-events-none', alt: "Medagogic Logo" }), _jsxs("div", __assign({ className: 'flex flex-col items-center justify-between gap-8 pb-24 w-3/4' }, { children: [_jsx("img", { src: Icon_Success, alt: "" }), _jsx("div", __assign({ className: 'bg-[#70ACB133] p-2 text-sm text-[#70ACB1] w-3/4 text-center' }, { children: "Assignment complete!" }))] })), _jsx("button", __assign({ className: "intro-button", onClick: function () { changeViewerState(ViewerState.IN_ASSIGNMENT); goFullScreen(); } }, { children: "View Assignment" }))] })));
    }
    if (viewerState < ViewerState.TUTORIAL) {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden items-center justify-between py-12' }, { children: [viewerState === ViewerState.LAUNCH_ASSIGNMENT &&
                            _jsx(_Fragment, { children: contextAssignmentData !== undefined ?
                                    _jsx(_Fragment, { children: _jsx(ViewerLaunchScreen, { onLaunch: onLaunchPressed }) })
                                    :
                                        _jsx(_Fragment, { children: _jsx(ViewerAssignmentList, { onSelectedAssignment: onSelectedAssignment }) }) }), viewerState === ViewerState.AUDIO_TEST &&
                            _jsx(ViewerAudioCheck, { onComplete: function () { return changeViewerState(ViewerState.SCENARIO_INTRO); } }), viewerState === ViewerState.SCENARIO_INTRO &&
                            _jsx(ViewerScenarioIntro, { onComplete: function () { return changeViewerState(ViewerState.IN_ASSIGNMENT); }, scenarioIntro: scenarioIntro })] })), isFullscreen ?
                    _jsx("div", __assign({ className: 'absolute right-0 top-0 p-6' }, { children: _jsx("img", { src: Icon_Close, alt: "Close", className: "h-4 w-4", onClick: exitFullScreen }) }))
                    :
                        _jsx("div", __assign({ className: 'absolute right-0 top-0 p-6' }, { children: _jsx(GoFullScreenIcon, { className: "h-6 w-6 text-gray-600 cursor-pointer", onClick: goFullScreen }) }))] }));
    }
    else if (viewerState <= ViewerState.IN_ASSIGNMENT) {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden' }, { children: [_jsxs("div", __assign({ id: "ue5-container", className: "flex-grow h-full relative" }, { children: [_jsx(ArcwareView, {}), _jsxs("div", __assign({ className: 'flex flex-col absolute left-0 bottom-0 h-auto z-[999999] justify-end p-2 pb-4 gap-2 pointer-events-none cursor-default w-16' }, { children: [_jsx("img", { src: showMonitor ? Button_Monitor_Active : Button_Monitor, alt: '', onClick: function () { return setShowMonitor(function (prev) { return !prev; }); }, className: 'pointer-events-auto cursor-pointer' }), _jsxs(DemoControlGroup, { children: [_jsx(ReportsControl, { props: { controlsMenuAlignment: "right" }, onToggleBloodGasReport: function () { return setShowBloodGasReport(function (prev) { return !prev; }); } }), _jsx(StethoscopeControl, { controlsMenuAlignment: "right" }), _jsx(ExaminationControl, { controlsMenuAlignment: "right" })] })] }))] })), viewerState === ViewerState.IN_ASSIGNMENT &&
                            _jsx(AssignmentQuestionDisplay, { onFinished: function () { return changeViewerState(ViewerState.EXERCISE_COMPLETE); } })] })), _jsx("div", __assign({ className: 'absolute left-0 top-0 p-2 z-[999999]' }, { children: _jsx("img", { src: MirnoIcon, alt: "Close", className: "h-8 w-8 pointer-events-none" }) })), isFullscreen ?
                    _jsx("div", __assign({ className: 'absolute right-0 top-0 p-6' }, { children: _jsx("img", { src: Icon_CloseLight, alt: "Close", className: "h-4 w-4", onClick: exitFullScreen }) }))
                    :
                        _jsx("div", __assign({ className: 'absolute right-0 top-0 p-6' }, { children: _jsx(GoFullScreenIcon, { className: "h-6 w-6 text-gray-600 cursor-pointer", onClick: goFullScreen }) })), showAssignmentInfoOverlay &&
                    _jsx("div", __assign({ className: "fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex flex-col", onClick: function () { return setShowAssignmentInfoOverlay(false); } }, { children: _jsx("div", __assign({ className: "relative mx-auto p-2 border w-1/2 shadow-lg rounded-md bg-white my-auto", onClick: function (e) { return e.stopPropagation(); } }, { children: _jsxs("div", __assign({ className: 'flex flex-col items-center justify-between gap-2' }, { children: [_jsx("img", { src: ScenarioInfoSVG, alt: "" }), _jsx("div", __assign({ className: 'bg-[#70ACB133] p-2 text-sm text-[#70ACB1] text-center flex flex-col gap-2' }, { children: _jsx(Markdown, { children: scenarioIntro }) }))] })) })) })), showMonitor &&
                    _jsx("div", __assign({ className: 'absolute top-12 left-2 right-2 z-[999999] p-1 rounded-md border border-white opacity-90 bg-teal-500', onClick: function () { setShowMonitor(false); } }, { children: _jsx(Monitor, { vitals: virtualPatientState.medicalState.vitals, deviceState: virtualPatientState.deviceStatus }) })), _jsx(AnimatePresence, { children: showBloodGasReport &&
                        _jsx(FullscreenBloodGasView, { onClose: function () { return setShowBloodGasReport(false); } }) })] }));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden items-center py-24 justify-between' }, { children: [viewerState === ViewerState.EXERCISE_COMPLETE &&
                            _jsx(ViewerComplete, { onFinished: finishedSubmittingAnswers, onBackClicked: function () { return changeViewerState(ViewerState.IN_ASSIGNMENT); } }), viewerState === ViewerState.ANSWERS_SUBMITTED &&
                            _jsx(ViewerAnswersSubmitted, { onComplete: function () { return navigate('/'); } })] })), isFullscreen ?
                    _jsx("div", __assign({ className: 'absolute right-0 top-0 p-6' }, { children: _jsx("img", { src: Icon_Close, alt: "Close", className: "h-4 w-4", onClick: exitFullScreen }) }))
                    :
                        _jsx("div", __assign({ className: 'absolute right-0 top-0 p-6' }, { children: _jsx(GoFullScreenIcon, { className: "h-6 w-6 text-gray-600 cursor-pointer", onClick: goFullScreen }) }))] }));
    }
};
export default AssignmentMode;
