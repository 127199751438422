var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import "./StartScreenStyle.css";
import MedagogicLogo from "../../assets/mirno_icon.png";
import Icon_Trophy from "./Icon_Trophy.svg";
import { useAssignmentData } from '../../components/StudentMode/AssignmentContext';
var ViewerComplete = function (_a) {
    var onFinished = _a.onFinished, onBackClicked = _a.onBackClicked;
    var _b = useAssignmentData(), assignmentResponse = _b.assignmentResponse, submitAssignmentData = _b.submitAssignmentData;
    var _c = React.useState(assignmentResponse.name), name = _c[0], setName = _c[1];
    var _d = React.useState(assignmentResponse.email), email = _d[0], setEmail = _d[1];
    var _e = React.useState(null), error = _e[0], setError = _e[1];
    var _f = React.useState(false), submitting = _f[0], setSubmitting = _f[1];
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedAssignmentResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    // Basic validation: Check if both fields are filled
                    if (!name || !email) {
                        setError('Please fill out both the name and email fields.');
                        return [2 /*return*/];
                    }
                    // Reset error if validation passes
                    setError(null);
                    updatedAssignmentResponse = __assign(__assign({}, assignmentResponse), { name: name, email: email });
                    setSubmitting(true);
                    return [4 /*yield*/, submitAssignmentData(updatedAssignmentResponse)];
                case 1:
                    _a.sent();
                    onFinished();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: MedagogicLogo, className: 'w-12 pointer-events-none', alt: "Medagogic Logo" }), _jsxs("div", __assign({ className: 'flex flex-col items-center justify-between gap-8 pb-24 w-3/4' }, { children: [_jsx("img", { src: Icon_Trophy, alt: "" }), _jsx("p", __assign({ className: 'text-[#70ACB1] text-lg' }, { children: "Exercise complete!" })), _jsxs("div", __assign({ className: 'bg-[#70ACB133] p-2 text-sm text-[#70ACB1] text-center h-auto flex flex-col gap-2' }, { children: [error && _jsx("p", __assign({ className: "text-red-500" }, { children: error })), submitting ? _jsx("p", __assign({ className: "text-[#70ACB1]" }, { children: "Submitting..." })) :
                                _jsx("p", { children: "Please enter your name and email address to complete the assignment." }), !submitting &&
                                _jsxs("form", __assign({ className: 'flex flex-col gap-2 text-lg mt-4', onSubmit: handleSubmit }, { children: [_jsx("input", { className: 'input-field', placeholder: 'Name', value: name || '', onChange: function (e) { return setName(e.target.value); }, id: "name", type: "text", autoComplete: 'name' }), _jsx("input", { className: 'input-field', placeholder: 'Email', value: email || '', onChange: function (e) { return setEmail(e.target.value); }, id: "email", type: "email", autoComplete: 'email' }), _jsx("button", __assign({ type: 'submit', className: "intro-button bg-[#70ACB1] text-white" }, { children: "Submit" }))] }))] }))] })), _jsx("div", __assign({ className: 'text-teal-600 text-sm', onClick: onBackClicked }, { children: "< Back" }))] }));
};
export default ViewerComplete;
