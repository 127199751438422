var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import AssignmentQuestionsList from './AssignmentQuestionsList';
import AssignmentQuestionEditor from './AssignmentQuestionEditor';
import { useAssignmentStore } from './AssignmentEditorStore';
var AssignmentEditor = function () {
    var _a = useAssignmentStore(), assignment = _a.assignment, setAssignment = _a.setAssignment;
    var _b = React.useState(0), selectedQuestionIndex = _b[0], setSelectedQuestionIndex = _b[1];
    var setAssignmentName = function (name) {
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            return __assign(__assign({}, prev), { name: name });
        });
    };
    var setAssignmentRootPatientId = function (rootPatientId) {
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            return __assign(__assign({}, prev), { rootPatientId: rootPatientId });
        });
    };
    var setAssignmentIntroduction = function (introduction) {
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            return __assign(__assign({}, prev), { introduction: introduction });
        });
    };
    if (!assignment) {
        return null;
    }
    return (_jsxs("div", __assign({ className: "w-full h-full" }, { children: [_jsxs("div", __assign({ className: 'flex gap-4' }, { children: [_jsxs("div", { children: [_jsx("label", { children: "Name" }), _jsx("input", { type: "text", value: assignment.name, onChange: function (e) { return setAssignmentName(e.target.value); } })] }), _jsxs("div", { children: [_jsx("label", { children: "VP ID" }), _jsx("input", { type: "text", value: assignment.rootPatientId, onChange: function (e) { return setAssignmentRootPatientId(e.target.value); } })] }), _jsxs("div", __assign({ className: 'w-full' }, { children: [_jsx("label", { children: "Intro" }), _jsx("input", { type: "text", value: assignment.introduction, className: 'w-full', onChange: function (e) { return setAssignmentIntroduction(e.target.value); } })] }))] })), _jsxs("div", __assign({ className: "flex flex-row w-full h-full" }, { children: [_jsx("div", __assign({ className: "w-1/4 bg-gray-100 h-full overflow-auto" }, { children: _jsx(AssignmentQuestionsList, { selectedQuestionId: selectedQuestionIndex, onQuestionSelected: setSelectedQuestionIndex }) })), _jsx("div", __assign({ className: "flex-grow bg-gray-200" }, { children: _jsx(AssignmentQuestionEditor, { selectedQuestionId: selectedQuestionIndex }) }))] }))] })));
};
export default AssignmentEditor;
