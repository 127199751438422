var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAssignmentStore } from './AssignmentEditorStore';
var AssignmentQuestionEditor = function (_a) {
    var selectedQuestionId = _a.selectedQuestionId;
    var _b = useState(0), selectedOptionId = _b[0], setSelectedOptionId = _b[1];
    var _c = useAssignmentStore(), assignment = _c.assignment, setAssignment = _c.setAssignment;
    function onOptionSelected(index) {
        setSelectedOptionId(index);
    }
    function handleRemoveOption(index) {
        if (!assignment)
            return;
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            var updatedQuestions = prev.questions.map(function (question, qIndex) {
                return qIndex === selectedQuestionId
                    ? __assign(__assign({}, question), { options: question.options.filter(function (_, optIndex) { return optIndex !== index; }) }) : question;
            });
            return __assign(__assign({}, prev), { questions: updatedQuestions });
        });
        // Reset the selected option ID
        setSelectedOptionId(0);
    }
    function handleAddOption() {
        if (!assignment)
            return;
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            var updatedQuestions = prev.questions.map(function (question, qIndex) {
                return qIndex === selectedQuestionId
                    ? __assign(__assign({}, question), { options: __spreadArray(__spreadArray([], question.options, true), [
                            { answer: '', learningInfo: '' },
                        ], false) }) : question;
            });
            return __assign(__assign({}, prev), { questions: updatedQuestions });
        });
        // Set the selected option ID to the newly added option
        setSelectedOptionId(assignment.questions[selectedQuestionId].options.length);
    }
    function handleOptionChange(event, field) {
        if (!assignment)
            return;
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            var updatedQuestions = prev.questions.map(function (question, qIndex) {
                return qIndex === selectedQuestionId
                    ? __assign(__assign({}, question), { options: question.options.map(function (option, optIndex) {
                            var _a;
                            return optIndex === selectedOptionId
                                ? __assign(__assign({}, option), (_a = {}, _a[field] = event.target.value, _a)) : option;
                        }) }) : question;
            });
            return __assign(__assign({}, prev), { questions: updatedQuestions });
        });
    }
    function handleQuestionChange(event) {
        if (!assignment)
            return;
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            var updatedQuestions = prev.questions.map(function (question, qIndex) {
                return qIndex === selectedQuestionId
                    ? __assign(__assign({}, question), { question: event.target.value }) : question;
            });
            return __assign(__assign({}, prev), { questions: updatedQuestions });
        });
    }
    function handleCorrectOptionChange(event) {
        if (!assignment)
            return;
        setAssignment(function (prev) {
            if (!prev)
                return prev;
            var updatedQuestions = prev.questions.map(function (question, qIndex) {
                return qIndex === selectedQuestionId
                    ? __assign(__assign({}, question), { correctAnswerId: Number(event.target.value) }) : question;
            });
            return __assign(__assign({}, prev), { questions: updatedQuestions });
        });
    }
    if (!assignment) {
        return null;
    }
    if (assignment.questions.length === 0) {
        return null;
    }
    var currentQuestion = assignment.questions[selectedQuestionId];
    return (_jsxs("div", __assign({ className: 'flex gap-1 p-1 bg-gray-50 rounded-lg shadow-md h-full' }, { children: [_jsxs("div", __assign({ className: 'w-1/2 h-full overflow-hidden flex flex-col' }, { children: [_jsxs("div", __assign({ className: 'mb-1 text-xs font-bold text-gray-700 flex' }, { children: [_jsx("textarea", { value: currentQuestion.question, onChange: handleQuestionChange, className: 'w-full p-1 border border-gray-300 rounded-lg text-xs' }), _jsx("select", __assign({ className: 'p-1 border border-gray-300 rounded-lg text-xs', value: currentQuestion.correctAnswerId, onChange: handleCorrectOptionChange }, { children: assignment.questions[selectedQuestionId].options.map(function (option, index) { return (_jsx("option", __assign({ value: index }, { children: index + 1 }), index)); }) }))] })), _jsx("div", __assign({ className: 'grow overflow-y-scroll' }, { children: _jsxs("div", __assign({ className: "flex flex-col p-1 bg-white rounded-lg shadow-lg" }, { children: [assignment.questions[selectedQuestionId].options.map(function (option, index) {
                                    var isSelected = selectedOptionId === index;
                                    return (_jsxs("div", __assign({ className: "flex flex-row items-center p-1 mb-1 rounded-lg cursor-pointer transition-colors duration-200 ".concat(isSelected ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-100'), onClick: function () { onOptionSelected(index); } }, { children: [_jsx("div", __assign({ className: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full text-white-800 font-bold mr-2 ".concat(index === assignment.questions[selectedQuestionId].correctAnswerId ? 'bg-green-200' : 'bg-red-200') }, { children: index + 1 })), _jsx("div", __assign({ className: "flex-grow overflow-hidden whitespace-nowrap overflow-ellipsis text-xs" }, { children: option.answer })), _jsx("button", __assign({ onClick: function (e) {
                                                    e.stopPropagation();
                                                    handleRemoveOption(index);
                                                }, className: "ml-2 bg-red-500 text-white p-1 rounded-lg button-sm text-xs" }, { children: "X" }))] }), index));
                                }), _jsx("button", __assign({ onClick: handleAddOption, className: "mt-2 bg-green-500 text-white p-1 rounded-lg text-xs" }, { children: "Add Answer" }))] })) }))] })), _jsx("div", __assign({ className: 'flex-grow' }, { children: _jsxs("div", __assign({ className: 'flex flex-col gap-2' }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ className: 'block mb-1 font-semibold text-gray-600 text-xs' }, { children: "Answer" })), _jsx("input", { type: 'text', value: assignment.questions[selectedQuestionId].options[selectedOptionId].answer, onChange: function (e) { return handleOptionChange(e, 'answer'); }, className: 'w-full p-1 border border-gray-300 rounded-lg text-xs' })] }), _jsxs("div", { children: [_jsx("label", __assign({ className: 'block mb-1 font-semibold text-gray-600 text-xs' }, { children: "Learning Info" })), _jsx("textarea", { value: assignment.questions[selectedQuestionId].options[selectedOptionId].learningInfo, onChange: function (e) { return handleOptionChange(e, 'learningInfo'); }, className: 'w-full p-1 border border-gray-300 rounded-lg text-xs' })] })] })) }))] })));
};
export default AssignmentQuestionEditor;
