var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import MedagogicLogo from "../../assets/mirno_icon.png";
import ScenarioInfoSVG from "./ScenarioInfoSymbol.svg";
import Markdown from 'react-markdown';
var ViewerScenarioIntro = function (_a) {
    var scenarioIntro = _a.scenarioIntro, onComplete = _a.onComplete;
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: MedagogicLogo, className: 'w-12 pointer-events-none', alt: "Medagogic Logo" }), _jsxs("div", __assign({ className: 'flex flex-col items-center justify-between gap-8 w-[80%]' }, { children: [_jsx("div", __assign({ className: '' }, { children: "SCENARIO" })), _jsx("img", { src: ScenarioInfoSVG, alt: "" }), _jsx("div", __assign({ className: 'bg-[#70ACB133] p-2 text-md text-[#70ACB1] w-full flex flex-col gap-2 max-h-44 overflow-y-scroll' }, { children: _jsx(Markdown, { children: scenarioIntro }) }))] })), _jsx("button", __assign({ className: "simple", onClick: onComplete }, { children: "Continue" }))] }));
};
export default ViewerScenarioIntro;
