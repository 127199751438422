var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import "./StartScreenStyle.css";
import MedagogicLogo from "../../assets/mirno_icon.png";
import { useAppContext } from '../../AppContext';
// import { AssignmentResponseData } from '../../components/StudentMode/AssignmentContext';
import { ClipLoader } from 'react-spinners';
import AssignmentStorage from '../../competency/AssignmentStorage';
var ViewerAssignmentList = function (_a) {
    var onSelectedAssignment = _a.onSelectedAssignment;
    // const [assignmentScenarios, ] = React.useState<RetrievedPatientPreset[]>([]);
    // const [assignmentResponseByAssignmentId, ] = React.useState<{ [scenarioId: string]: AssignmentResponseData }>({});
    var _b = React.useState([]), assignments = _b[0], setAssignments = _b[1];
    var _c = React.useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var authedUser = useAppContext().authedUser;
    useEffect(function () {
        // if (!authedUser) return;
        var loadAssignments = function () { return __awaiter(void 0, void 0, void 0, function () {
            var loadedAssignments;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, AssignmentStorage.getAllAssignments()];
                    case 1:
                        loadedAssignments = _a.sent();
                        setAssignments(loadedAssignments);
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        loadAssignments();
    }, [authedUser]);
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: MedagogicLogo, className: 'w-12 pointer-events-none', alt: "Medagogic Logo" }), _jsx("div", __assign({ className: 'flex flex-col items-center justify-between gap-8 pb-24 w-3/4' }, { children: isLoading ? (_jsxs("div", __assign({ className: 'flex justify-center items-center flex-col gap-4' }, { children: [_jsx("div", { children: _jsx("p", __assign({ className: 'text-lg' }, { children: "Loading assignments..." })) }), _jsx(ClipLoader, { loading: isLoading, size: 50 })] }))) : assignments.length > 0 ? (_jsxs("div", __assign({ className: 'flex flex-col items-center gap-4' }, { children: [_jsx("h1", __assign({ className: 'text-lg' }, { children: "Available Assignments" })), _jsx("div", __assign({ className: 'flex flex-col gap-4' }, { children: assignments.map(function (assignment, index) { return (_jsx("div", __assign({ className: 'flex items-center justify-between w-full p-4 bg-white rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-100', onClick: function () { return onSelectedAssignment(assignment); } }, { children: _jsx("div", __assign({ className: 'flex items-center gap-4' }, { children: _jsx("p", __assign({ className: 'text-md' }, { children: assignment.name })) })) }), index)); }) }))] }))) : (_jsx("div", __assign({ className: 'flex flex-col items-center gap-4' }, { children: _jsx("h1", __assign({ className: 'text-2xl font-bold' }, { children: "No assignments" })) }))) }))] }));
};
export default ViewerAssignmentList;
